import { useState, useRef, useEffect } from "react";
import { usePathname } from "next/navigation";
import Link from "next/link";
import SidebarItem from "./SidebarItem";

const Sidebar = ({ items }) => {
  const pathname = usePathname();
  const [openPanel, setOpenPanel] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [itemPositions, setItemPositions] = useState({});
  const sidebarRef = useRef(null);
  const panelContainerRef = useRef(null);
  const itemRefs = useRef({});
  const panelRefs = useRef({});

  // Find active items based on current pathname
  const findActiveItems = () => {
    // Check for direct match in main items
    const activeMainItem = items.find(
      (item) => item.url && pathname === item.url
    );

    if (activeMainItem) {
      return { mainItem: activeMainItem.id, childItem: null };
    }

    // Check for match in children
    for (const item of items) {
      if (item.children) {
        const activeChild = item.children.find(
          (child) => child.url && pathname === child.url
        );

        if (activeChild) {
          return { mainItem: item.id, childItem: activeChild.id };
        }
      }
    }

    return { mainItem: null, childItem: null };
  };

  const { mainItem: activeMainItem, childItem: activeChildItem } =
    findActiveItems();

  // Update item positions when items change or on window resize
  useEffect(() => {
    const updateItemPositions = () => {
      const positions = {};

      items.forEach((item) => {
        const itemRef = itemRefs.current[item.id];
        if (itemRef) {
          const rect = itemRef.getBoundingClientRect();
          const sidebarRect = sidebarRef.current.getBoundingClientRect();

          positions[item.id] = {
            top: rect.top - sidebarRect.top,
            height: rect.height,
          };
        }
      });

      setItemPositions(positions);
    };

    // Initial position calculation
    updateItemPositions();

    // Update positions on window resize
    window.addEventListener("resize", updateItemPositions);

    return () => {
      window.removeEventListener("resize", updateItemPositions);
    };
  }, [items]);

  // Adjust panel position if it goes out of viewport
  useEffect(() => {
    const adjustPanelPosition = () => {
      const currentOpenItem = hoveredItem || openPanel;
      if (!currentOpenItem) return;

      const panelRef = panelRefs.current[currentOpenItem];
      if (!panelRef) return;

      const panelRect = panelRef.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (panelRect.bottom > viewportHeight) {
        // If panel extends beyond bottom of viewport, adjust its top position
        const overflow = panelRect.bottom - viewportHeight;
        panelRef.style.top = `${
          Number.parseFloat(panelRef.style.top) - overflow - 20
        }px`;
      }
    };

    // Run after panel is rendered
    setTimeout(adjustPanelPosition, 0);
  }, [hoveredItem, openPanel]);

  // Set initial open panel based on active route when component mounts
  useEffect(() => {
    if (activeMainItem && openPanel === null) {
      setOpenPanel(activeMainItem);
    }
  }, [activeMainItem]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handle clicks outside the sidebar to close open panels
  useEffect(() => {
    const handleClickOutside = (event) => {
      // If we have an open panel (not just hovered)
      if (openPanel) {
        // Check if the click is outside both the sidebar and the panel container
        if (
          sidebarRef.current &&
          !sidebarRef.current.contains(event.target) &&
          panelContainerRef.current &&
          !panelContainerRef.current.contains(event.target)
        ) {
          setOpenPanel(null);
        }
      }
    };

    // Add event listener to document body
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openPanel]);

  const handleMouseEnter = (itemId) => {
    setHoveredItem(itemId);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleItemClick = (itemId) => {
    // Toggle the panel open/closed state
    setOpenPanel((prevOpenPanel) => (prevOpenPanel === itemId ? null : itemId));
  };

  // Calculate panel position for an item
  const getPanelStyle = (itemId) => {
    const position = itemPositions[itemId];
    if (!position) return {};

    return {
      top: `${position.top}px`,
    };
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar" ref={sidebarRef}>
        {items.map((item) => (
          <SidebarItem
            key={item.id}
            item={item}
            // Panel is open if it's being hovered or explicitly opened
            isPanelOpen={openPanel === item.id || hoveredItem === item.id}
            // Item is route-active if it matches the current route
            isRouteActive={activeMainItem === item.id}
            isHovered={hoveredItem === item.id}
            onClick={() => handleItemClick(item.id)}
            onMouseEnter={() => handleMouseEnter(item.id)}
            onMouseLeave={handleMouseLeave}
            ref={(el) => (itemRefs.current[item.id] = el)}
          />
        ))}
      </div>

      <div ref={panelContainerRef}>
        {items.map(
          (item) =>
            item.children &&
            (hoveredItem === item.id || openPanel === item.id) && (
              <div
                key={`panel-${item.id}`}
                className="sidebar-panel"
                style={getPanelStyle(item.id)}
                onMouseEnter={() => handleMouseEnter(item.id)}
                onMouseLeave={handleMouseLeave}
                ref={(el) => (panelRefs.current[item.id] = el)}
              >
                <div className="panel-header">{item.label}</div>
                <div className="panel-content">
                  {item.children.map((child) => (
                    <PanelItem
                      key={child.id}
                      item={child}
                      isActive={activeChildItem === child.id}
                    />
                  ))}
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

// Panel Item component with Link support
const PanelItem = ({ item, isActive }) => {
  return (
    <Link
      href={item.url || "#"}
      className={`panel-item ${isActive ? "active" : ""}`}
    >
      <div className="panel-item-icon">{item.icon}</div>
      <div className="panel-item-label">{item.label}</div>
    </Link>
  );
};

export default Sidebar;
