import { forwardRef } from "react";
import Link from "next/link";

const SidebarItem = forwardRef(
  (
    {
      item,
      isPanelOpen,
      isRouteActive,
      isHovered,
      onClick,
      onMouseEnter,
      onMouseLeave,
    },
    ref
  ) => {
    // Determine if this is a link or a button (for items with children)
    const hasChildren = !!item.children && item.children.length > 0;

    // For items with children, we want to handle the click to show the panel
    // For items without children, we want to navigate to the URL
    const handleClick = (e) => {
      if (hasChildren) {
        e.preventDefault();
        onClick();
      }
    };

    const content = (
      <>
        <div className="sidebar-item-icon">{item.icon}</div>
        {(isHovered || isPanelOpen) && (
          <div className="sidebar-tooltip">{item.label}</div>
        )}
        {hasChildren && (
          <div
            className={`has-children-indicator ${isPanelOpen ? "open" : ""}`}
          ></div>
        )}
      </>
    );

    return (
      <div
        ref={ref}
        className={`sidebar-item ${isPanelOpen ? "panel-open" : ""} ${
          isRouteActive ? "route-active" : ""
        }`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {hasChildren ? (
          <button className="sidebar-button" onClick={onClick}>
            {content}
          </button>
        ) : (
          <Link
            href={item.url || "#"}
            className="sidebar-link"
            onClick={handleClick}
          >
            {content}
          </Link>
        )}
      </div>
    );
  }
);

SidebarItem.displayName = "SidebarItem";

export default SidebarItem;
