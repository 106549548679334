import React from "react";
import Link from "next/link";
import Select from "react-select";
import useFetcher from "@hooks/fetcher";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useSession, signOut } from "next-auth/react";
import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
} from "@novu/notification-center";

const TopNav = () => {
  const { data: session, update } = useSession();
  const { locales, push, pathname, asPath } = useRouter();
  const { t } = useTranslation("common");

  const updateSession = async (company) => {
    company.current = true;

    await update({
      ...session,
      user: {
        ...session.user,
        currentCompany: company,
      },
    });
  };

  const leaveImpersonation = async () => {
    await update({
      ...session,
      leave_impersonation: true,
    });
  };

  const { data, error } = useFetcher("/api/v1/companies/my-companies");

  React.useEffect(() => {
    function updateMargin() {
      const rightElement = document.getElementById("top-nav");
      const leftElement = document.getElementById("sidenav-main");

      // check if the aside element is visible in the viewport
      const leftRect = leftElement?.getBoundingClientRect();

      if (leftRect && leftRect.right > 0 && leftRect.left < window.innerWidth) {
        const leftWidth = leftElement.clientWidth;
        rightElement.style.marginLeft = leftWidth + "px";
      } else {
        rightElement && (rightElement.style.marginLeft = "0px");
      }
    }

    updateMargin();
    window.addEventListener("resize", updateMargin);
    window.addEventListener("scroll", updateMargin);
  }, []);

  const avatarColors = [
    "bota-dark-cyan-lime-green",
    "bota-strong-cyan-lime-green",
    "bota-light-blue",
    "dark",
    "primary",
    "info",
    "warning",
  ];

  const companies = data?.companies?.map((company) => {
    const randomColor =
      avatarColors[Math.floor(Math.random() * avatarColors.length)];

    return {
      label: (
        <>
          <span className={`avatar avatar-sm rounded-circle bg-${randomColor}`}>
            <span className="avatar-content">
              {company?.name.charAt(0).toUpperCase() +
                company?.name
                  .split(" ")
                  .slice(-1)
                  .pop()
                  .charAt(0)
                  .toUpperCase()}
            </span>
          </span>
          <span className="ms-3">{company?.name}</span>
          {session.user?.currentCompany?.id == company.id && (
            <i className="ms-2 text-dark fas fa-check" />
          )}
        </>
      ),
      value: company.id,
    };
  });

  return (
    <nav
      className="navbar navbar-top navbar navbar-main navbar-expand-lg sticky-top px-0 opacity-9 shadow-lg bg-white"
      data-scroll="true"
      style={{ zIndex: 99, marginLeft: "0px !important" }}
      id="top-nav"
    >
      <div className="container-fluid my-2">
        <ul className="nav" style={{ marginLeft: "auto" }}>
          {session && session?.admin_user && (
            <li className="mx-2">
              <button
                className="btn btn-bota-light-blue text-capitalize"
                type="button"
                onClick={leaveImpersonation}
              >
                {t("buttons:leave-impersonation")}
              </button>
            </li>
          )}

          {companies && companies.length >= 1 ? (
            <li className="dropdown me-3">
              <Select
                value={companies?.filter(
                  (c) => c.value == session.user?.currentCompany?.id
                )}
                onChange={(val) =>
                  updateSession(data?.companies?.find((c) => c.id == val.value))
                }
                options={companies}
                defaultValue={session.user?.currentCompany?.id}
              />
            </li>
          ) : (
            <li className="dropdown me-3">
              <Link href="/companies" className="text-bota-light-blue" passHref>
                <i className="fas fa-building" />
                <span className="ms-3">
                  {t("common:top-nav.create-a-company")}
                </span>
              </Link>
            </li>
          )}

          <li className="dropdown me-3">
            <i
              className="fas fa-language fa-2x"
              style={{
                color: "var(--bs-bota-strong-cyan-lime-green)",
              }}
              data-bs-toggle="dropdown"
              aria-expanded="false"
            />
            <ul className="dropdown-menu dropdown-menu-end">
              {locales.map((locale) => (
                <li
                  key={locale}
                  onClick={() => {
                    push(pathname, asPath, { locale });
                    localStorage.setItem("BOTATENDE_LOCALE", locale);
                  }}
                >
                  <a className="dropdown-item" href="#">
                    <span>
                      <i className="fas fa-globe" />
                      <span className="ms-3">
                        {locale === "en"
                          ? "English"
                          : locale === "pt"
                          ? "Português"
                          : locale}
                      </span>
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </li>

          <li className="mx-2">
            <NovuProvider
              backendUrl={process.env.NEXT_PUBLIC_NOVU_BACKEND_URL}
              socketUrl={process.env.NEXT_PUBLIC_NOVU_SOCKET_URL}
              applicationIdentifier={process.env.NEXT_PUBLIC_NOVU_APP_ID}
              subscriberId={session?.user?.subscriberId}
              subscriberHash={session?.user?.subscriberHash}
              styles={{
                footer: {
                  root: { display: "none" },
                },
              }}
            >
              <PopoverNotificationCenter colorScheme={"light"}>
                {({ unseenCount }) => (
                  <NotificationBell unseenCount={unseenCount} />
                )}
              </PopoverNotificationCenter>
            </NovuProvider>
          </li>

          <li className="dropdown">
            <span
              className="avatar avatar-sm rounded-circle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                backgroundColor: "var(--bs-bota-strong-cyan-lime-green)",
              }}
            >
              <i
                className="fas fa-user fa-2x"
                style={{
                  color: "var(--bs-bota-light-grayish-orange)",
                }}
              />
            </span>
            <ul
              className="dropdown-menu menu-2 dropdown-menu-end"
              style={{
                maxHeight: "500px",
                overflowY: "auto",
              }}
            >
              {/* profile */}
              <li>
                <Link href="/profile" className="dropdown-item" passHref>
                  <i className="fas fa-user" />
                  <span className="ms-3">{t("common:top-nav.my-profile")}</span>
                </Link>
              </li>

              {/* billing */}
              <li>
                <Link href="/billing" className="dropdown-item" passHref>
                  <i className="fas fa-file-invoice-dollar" />
                  <span className="ms-3">{t("common:menu.billing")}</span>
                </Link>
              </li>

              <hr className="dropdown-divider" />
              {session && (
                <>
                  <hr className="dropdown-divider" />
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => signOut({ redirect: false })}
                    >
                      <i className="fas fa-person-running" />
                      <span className="ms-3">{t("common:top-nav.logout")}</span>
                    </a>
                  </li>

                  {/* affiliates */}
                  <li className="p-2">
                    <Link href="/affiliates" className="dropdown-item" passHref>
                      <div
                        className="border border-warning text-dark p-1"
                        style={{ backgroundColor: "#ffc480" }}
                      >
                        <p>{t("common:top-nav:referral")}</p>
                      </div>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default TopNav;
