import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Header from "./header";
import Footer from "./navigation/Footer";
import TopNav from "./navigation/TopNav";
import { ToastContainer } from "react-toastify";
import { Sidebar } from "../components/Sidebar";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import BusinessIcon from "@mui/icons-material/Business";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SupportIcon from "@mui/icons-material/Support";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DeviceHubOutlinedIcon from "@mui/icons-material/DeviceHubOutlined";
import FaceRetouchingNaturalOutlinedIcon from "@mui/icons-material/FaceRetouchingNaturalOutlined";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import WebhookOutlinedIcon from "@mui/icons-material/WebhookOutlined";
import ApiOutlinedIcon from "@mui/icons-material/ApiOutlined";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import MarkChatReadOutlinedIcon from "@mui/icons-material/MarkChatReadOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import QuickreplyOutlinedIcon from "@mui/icons-material/QuickreplyOutlined";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import SwitchAccountOutlinedIcon from "@mui/icons-material/SwitchAccountOutlined";
import Groups3OutlinedIcon from "@mui/icons-material/Groups3Outlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import SmartButtonOutlinedIcon from "@mui/icons-material/SmartButtonOutlined";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SendTimeExtensionOutlinedIcon from "@mui/icons-material/SendTimeExtensionOutlined";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import PhotoFilterOutlinedIcon from "@mui/icons-material/PhotoFilterOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

const AuthLayout = ({ children, showMobilemenu }) => {
  const { data: session } = useSession();
  const { t } = useTranslation("common");
  const router = useRouter();
  const location = router.pathname;

  // Build sidebar items based on user role and permissions
  const buildSidebarItems = () => {
    const items = [];

    // Dashboard - for all users
    items.push({
      id: "dashboard",
      icon: <AssessmentOutlinedIcon />,
      label: t("common:menu.dashboard"),
      url: "/dashboard",
      isRouteActive:
        location === "/dashboard" || location.includes("/dashboard"),
    });

    // WhatsApp - for admin or meta@botatende.com
    if (
      session?.user?.email === "meta@botatende.com" ||
      session?.user?.role === "admin"
    ) {
      items.push({
        id: "whatsapp",
        icon: <WhatsAppIcon />,
        label: t("common:menu.whatsapp"),
        isRouteActive:
          location === "/whatsapp" ||
          location.includes("/whatsapp/templates") ||
          location.includes("/whatsapp/embedded-signup"),
        children: [
          {
            id: "waba-accounts",
            icon: <WhatsAppIcon />,
            label: t("common:menu.waba-accounts"),
            url: "/whatsapp",
            isRouteActive: location === "/whatsapp",
          },
          {
            id: "message-templates",
            icon: <MarkChatReadOutlinedIcon />,
            label: t("common:menu.message-templates"),
            url: "/whatsapp/templates",
            isRouteActive: location.includes("/whatsapp/templates"),
          },
        ],
      });

      // Instagram connection
      items.push({
        id: "instagram",
        icon: <InstagramIcon />,
        label: t("common:menu.instagram"),
        isRouteActive:
          location === "/instagram" ||
          location.includes("/instagram/templates") ||
          location.includes("/instagram/embedded-signup"),
        children: [
          {
            id: "instagram-accounts",
            icon: <InstagramIcon />,
            label: t("common:menu.instagram-accounts"),
            url: "/instagram",
            isRouteActive: location === "/instagram",
          },
        ],
      });
    }

    // Companies menu - for all users
    items.push({
      id: "companies",
      icon: <ApartmentOutlinedIcon />,
      label: t("common:menu.companies"),
      url: "/companies",
      isRouteActive: location.includes("/companies"),
    });

    // All companies menu - for admin only
    if (session?.user?.role === "admin") {
      items.push({
        id: "all-companies",
        icon: <BusinessOutlinedIcon />,
        label: t("common:menu.all-companies"),
        url: "/all-companies",
        isRouteActive: location.includes("/all-companies"),
      });

      // Subscription and invoices - for admin only
      items.push({
        id: "subscriptions-mgmt",
        icon: <PaymentsOutlinedIcon />,
        label: t("common:menu.subscriptions"),
        isRouteActive:
          location.includes("/admin/invoices") ||
          location.includes("/admin/subscription-plans"),
        children: [
          {
            id: "subscription-plans",
            icon: <PriceChangeOutlinedIcon />,
            label: t("common:menu.subscription-plans"),
            url: "/admin/subscription-plans",
            isRouteActive: location.includes("/admin/subscription-plans"),
          },
          {
            id: "customer-invoices",
            icon: <ReceiptOutlinedIcon />,
            label: t("common:menu.customer-invoices"),
            url: "/admin/invoices",
            isRouteActive: location.includes("/admin/invoices"),
          },
        ],
      });

      // User management - for admin only
      items.push({
        id: "user-management",
        icon: <ManageAccountsOutlinedIcon />,
        label: t("common:menu.user-management"),
        isRouteActive:
          location.includes("/admin/company-permissions") ||
          location.includes("/admin/permissions") ||
          location.includes("/admin/roles") ||
          location.includes("/admin/users/"),
        children: [
          {
            id: "roles",
            icon: <AdminPanelSettingsOutlinedIcon />,
            label: t("common:menu.roles"),
            url: "/admin/roles",
            isRouteActive:
              location === "/admin/roles" || location.includes("/admin/roles"),
          },
          {
            id: "permissions",
            icon: <LockPersonOutlinedIcon />,
            label: t("common:menu.permissions"),
            url: "/admin/permissions",
            isRouteActive:
              location === "/admin/permissions" ||
              location.includes("/admin/permissions"),
          },
          {
            id: "company-permissions",
            icon: <LockOpenOutlinedIcon />,
            label: t("common:menu.company-permissions"),
            url: "/admin/company-permissions",
            isRouteActive:
              location === "/admin/company-permissions" ||
              location.includes("/admin/company-permissions"),
          },
          {
            id: "customers",
            icon: <PeopleAltOutlinedIcon />,
            label: t("common:menu.customers"),
            url: "/admin/users/customers",
            isRouteActive:
              location === "/admin/users/customers" ||
              location.includes("/admin/users/customers"),
          },
          {
            id: "customer-support",
            icon: <SupportAgentIcon />,
            label: t("common:menu.customer-support"),
            url: "/admin/users/customer-support",
            isRouteActive:
              location === "/admin/users/customer-support" ||
              location.includes("/admin/users/customer-support"),
          },
          {
            id: "admins",
            icon: <GppGoodOutlinedIcon />,
            label: t("common:menu.admins"),
            url: "/admin/users/admins",
            isRouteActive:
              location === "/admin/users/admins" ||
              location.includes("/admin/users/admins"),
          },
        ],
      });
    }

    // Company specific items
    if (session?.user?.currentCompany) {
      // Integrations
      if (session?.user?.currentCompany.access_blocks?.includes("channels")) {
        items.push({
          id: "channels",
          icon: <DeviceHubOutlinedIcon />,
          label: t("common:menu.channels"),
          isRouteActive: location.includes("/channels/"),
          children: [
            {
              id: "whatsapp-integration",
              icon: <WhatsAppIcon />,
              label: t("common:menu.whatsapp"),
              url: "/channels/whatsapp",
              isRouteActive: location === "/channels/whatsapp",
            },
          ],
        });
      }

      // Global Inbox
      if (session?.user?.currentCompany.access_blocks?.includes("inbox")) {
        items.push({
          id: "whatsapp-chat",
          icon: <ForumOutlinedIcon />,
          label: t("common:menu.whatsapp-chat"),
          url: "/channels/inbox",
          isRouteActive:
            location === "/channels/inbox" ||
            location.includes("/channels/inbox"),
        });
      }

      // Contacts
      items.push({
        id: "contacts",
        icon: <ContactPageOutlinedIcon />,
        label: t("common:menu.contacts"),
        url: "/company/contacts",
        isRouteActive:
          location === "/company/contacts" ||
          location.includes("/company/contacts"),
      });

      // Smart agent module
      if (
        session.user?.currentCompany?.access_blocks?.includes("smart_agent") ||
        session?.user?.role === "admin"
      ) {
        items.push({
          id: "smart-reply",
          icon: <FaceRetouchingNaturalOutlinedIcon />,
          label: t("common:menu.smart-reply"),
          isRouteActive:
            location.includes("/smart-agent/agents") ||
            location.includes("/smart-agent/all-agents") ||
            location.includes("/smart-agent/tools") ||
            location.includes("/smart-agent/voices") ||
            location.includes("/smart-agent/library") ||
            location.includes("/calendar") ||
            location.includes("/smart-agent/knowledge-base"),
          children: [
            {
              id: "agents",
              icon: <FaceRetouchingNaturalOutlinedIcon />,
              label: t("common:menu.agents"),
              url: "/smart-agent/agents",
              isRouteActive:
                location === "/smart-agent/agents" ||
                location.includes("/smart-agent/agents"),
            },
            {
              id: "knowledge-base",
              icon: <TipsAndUpdatesOutlinedIcon />,
              label: t("common:menu.knowledge-base"),
              url: "/smart-agent/knowledge-base",
              isRouteActive:
                location === "/smart-agent/knowledge-base" ||
                location.includes("/smart-agent/knowledge-base"),
            },
            {
              id: "smart-tools",
              icon: <SettingsSuggestOutlinedIcon />,
              label: t("common:menu.smart-tools"),
              url: "/smart-agent/tools",
              isRouteActive:
                location === "/smart-agent/tools" ||
                location.includes("/smart-agent/tools"),
            },
            {
              id: "voice",
              icon: <KeyboardVoiceOutlinedIcon />,
              label: t("common:menu.voice"),
              url: "/smart-agent/voices",
              isRouteActive:
                location === "/smart-agent/voices" ||
                location.includes("/smart-agent/voices"),
            },
            {
              id: "calendar",
              icon: <CalendarMonthOutlinedIcon />,
              label: t("common:menu.calendar"),
              url: "/calendar",
              isRouteActive:
                location === "/calendar" || location.includes("/calendar"),
            },
            // Admin only
            ...(session?.user?.role === "admin"
              ? [
                  {
                    id: "all-agents",
                    icon: <SwitchAccountOutlinedIcon />,
                    label: t("common:menu.all-agents"),
                    url: "/smart-agent/all-agents",
                    isRouteActive:
                      location === "/smart-agent/all-agents" ||
                      location.includes("/smart-agent/all-agents"),
                  },
                  // Agents library
                  {
                    id: "agents-library",
                    icon: <Groups3OutlinedIcon />,
                    label: t("common:menu.agent-library"),
                    url: "/smart-agent/library",
                    isRouteActive:
                      location === "/smart-agent/library" ||
                      location.includes("/smart-agent/library"),
                  },
                ]
              : []),
          ],
        });
      }

      // Bulk sending
      if (
        session?.user?.currentCompany.access_blocks?.includes("bulk_sending")
      ) {
        items.push({
          id: "bulk-sending",
          icon: <RocketLaunchOutlinedIcon />,
          label: t("common:menu.bulk-sending"),
          isRouteActive:
            location.includes("/company/messages") ||
            location.includes("/company/campaigns") ||
            location.includes("/all-campaigns") ||
            location.includes("/company/actions") ||
            location.includes("/smart-agent/auto-reply"),
          children: [
            {
              id: "messages",
              icon: <AccountTreeOutlinedIcon />,
              label: t("common:menu.messages"),
              url: "/company/messages",
              isRouteActive:
                location === "/company/messages" ||
                location.includes("/company/messages"),
            },
            {
              id: "my-campaigns",
              icon: <ScheduleSendOutlinedIcon />,
              label: t("common:menu.my-campaigns"),
              url: "/company/campaigns",
              isRouteActive:
                location === "/company/campaigns" ||
                location.includes("/company/campaigns"),
            },
            {
              id: "actions",
              icon: <BoltOutlinedIcon />,
              label: t("common:menu.actions"),
              url: "/company/actions",
              isRouteActive:
                location === "/company/actions" ||
                location.includes("/company/actions"),
            },
            {
              id: "auto-reply",
              icon: <QuickreplyOutlinedIcon />,
              label: t("common:menu.auto-reply"),
              url: "/smart-agent/auto-reply",
              isRouteActive:
                location === "/smart-agent/auto-reply" ||
                location.includes("/smart-agent/auto-reply"),
            },
            // Admin only
            ...(session?.user?.role === "admin"
              ? [
                  {
                    id: "all-campaigns",
                    icon: <PendingActionsOutlinedIcon />,
                    label: t("common:menu.all-campaigns"),
                    url: "/all-campaigns",
                    isRouteActive:
                      location === "/all-campaigns" ||
                      location.includes("/all-campaigns"),
                  },
                ]
              : []),
          ],
        });
      }

      // Chatbot
      if (session?.user?.currentCompany.access_blocks?.includes("chatbot")) {
        items.push({
          id: "chatbot",
          icon: <SmartToyOutlinedIcon />,
          label: t("common:menu.chatbot"),
          url: "https://chatbot.smart.botatende.com",
          isExternal: true,
        });
      }

      // Automations
      if (
        session?.user?.currentCompany.access_blocks?.includes("automations")
      ) {
        items.push({
          id: "automations",
          icon: <HubOutlinedIcon />,
          label: t("common:menu.integrations"),
          isRouteActive:
            location.includes("/company/webhook-callback") ||
            location.includes("/admin/webhook-callback") ||
            location.includes("/company/apis"),
          children: [
            // Admin only
            ...(session?.user?.role === "admin"
              ? [
                  {
                    id: "webhook-templates",
                    icon: <TokenOutlinedIcon />,
                    label: t("common:menu.webhook-templates"),
                    url: "/admin/webhook-callback/templates",
                    isRouteActive:
                      location === "/admin/webhook-callback/templates" ||
                      location.includes("/admin/webhook-callback/templates"),
                  },
                  {
                    id: "payment-integrations",
                    icon: <CurrencyExchangeIcon />,
                    label: t("common:menu.payments-integration"),
                    url: "/admin/payment-integrations",
                    isRouteActive:
                      location === "/admin/payment-integrations" ||
                      location.includes("/admin/payment-integrations"),
                  },
                ]
              : []),
            {
              id: "webhook-integrations",
              icon: <WebhookOutlinedIcon />,
              label: t("common:menu.webhook-integrations"),
              url: "/company/webhook-integrations",
              isRouteActive:
                location === "/company/webhook-integrations" ||
                location.includes("/company/webhook-integrations"),
            },
            {
              id: "apis",
              icon: <ApiOutlinedIcon />,
              label: t("common:menu.apis"),
              url: "/company/apis",
              isRouteActive:
                location === "/company/apis" ||
                location.includes("/company/apis"),
            },
          ],
        });
      }
    }

    // Admin specific items
    if (session?.user?.role === "admin") {
      // External APIs
      items.push({
        id: "bsmart-management",
        icon: <PhotoFilterOutlinedIcon />,
        label: t("common:menu.bsmart-management"),
        isRouteActive:
          location.includes("/external/partner-management") ||
          location.includes("/external/models-management") ||
          location.includes("/external/company-management") ||
          location.includes("/external/agent-management") ||
          location.includes("/external/voice-management"),
        children: [
          {
            id: "partner-management",
            icon: <HandshakeOutlinedIcon />,
            label: t("common:menu.partner-management"),
            url: "/external/partner-management",
            isRouteActive:
              location === "/external/partner-management" ||
              location.includes("/external/partner-management"),
          },
          {
            id: "models-management",
            icon: <SmartButtonOutlinedIcon />,
            label: t("common:menu.models-management"),
            url: "/external/models-management",
            isRouteActive:
              location === "/external/models-management" ||
              location.includes("/external/models-management"),
          },
          {
            id: "company-management",
            icon: <BusinessIcon />,
            label: t("common:menu.company-management"),
            url: "/external/company-management",
            isRouteActive:
              location === "/external/company-management" ||
              location.includes("/external/company-management"),
          },
          {
            id: "agent-management",
            icon: <SwitchAccountOutlinedIcon />,
            label: t("common:menu.agent-management"),
            url: "/external/agent-management",
            isRouteActive:
              location === "/external/agent-management" ||
              location.includes("/external/agent-management"),
          },
          {
            id: "voice-management",
            icon: <KeyboardVoiceOutlinedIcon />,
            label: t("common:menu.voice-management"),
            url: "/external/voice-management",
            isRouteActive:
              location === "/external/voice-management" ||
              location.includes("/external/voice-management"),
          },
        ],
      });

      // Settings
      items.push({
        id: "settings",
        icon: <TuneOutlinedIcon />,
        label: t("common:menu.settings"),
        isRouteActive:
          location.includes("/admin/whatsapp-channels") ||
          location.includes("/admin/messages") ||
          location.includes("/admin/campaigns") ||
          location.includes("/settings/general") ||
          location.includes("/settings/system-emails"),
        children: [
          {
            id: "general",
            icon: <SettingsOutlinedIcon />,
            label: t("common:menu.general"),
            url: "/settings/general",
            isRouteActive:
              location === "/settings/general" ||
              location.includes("/settings/general"),
          },
          {
            id: "system-emails",
            icon: <SendTimeExtensionOutlinedIcon />,
            label: t("common:menu.system-emails"),
            url: "/settings/system-emails",
            isRouteActive:
              location === "/settings/system-emails" ||
              location.includes("/settings/system-emails"),
          },
          {
            id: "whatsapp-settings",
            icon: <WhatsAppIcon />,
            label: t("common:menu.whatsapp"),
            url: "/admin/whatsapp-channels",
            isRouteActive:
              location === "/admin/whatsapp-channels" ||
              location.includes("/admin/whatsapp-channels"),
          },
          {
            id: "messages-settings",
            icon: <AccountTreeOutlinedIcon />,
            label: t("common:menu.messages"),
            url: "/admin/messages",
            isRouteActive:
              location === "/admin/messages" ||
              location.includes("/admin/messages"),
          },
          {
            id: "system-campaigns",
            icon: <ScheduleSendOutlinedIcon />,
            label: t("common:menu.system-campaigns"),
            url: "/admin/campaigns",
            isRouteActive:
              location === "/admin/campaigns" ||
              location.includes("/admin/campaigns"),
          },
        ],
      });
    }

    // Support tickets - for admin or support
    if (session?.user?.role === "admin" || session?.user?.role === "support") {
      items.push({
        id: "support-requests",
        icon: <SupportIcon />,
        label: t("common:menu.support-requests"),
        url: "/support/tickets",
        isRouteActive:
          location === "/support/tickets" ||
          location.includes("/support/tickets"),
      });

      items.push({
        id: "support-customers",
        icon: <PersonSearchOutlinedIcon />,
        label: t("common:menu.customers"),
        url: "/support/customers",
        isRouteActive:
          location === "/support/customers" ||
          location.includes("/support/customers"),
      });
    }

    // Support tickets - for customers
    if (session?.user?.role === "customer") {
      items.push({
        id: "support-tickets",
        icon: <SupportIcon />,
        label: t("common:menu.support-tickets"),
        url: "/company/support",
        isRouteActive:
          location === "/company/support" ||
          location.includes("/company/support"),
      });
    }

    // Profile menu - for all users
    items.push({
      id: "profile",
      icon: <BadgeOutlinedIcon />,
      label: t("common:menu.profile"),
      url: "/profile",
      isRouteActive: location === "/profile" || location.includes("/profile"),
    });

    // Billing menu - for all users
    items.push({
      id: "billing",
      icon: <ReceiptLongIcon />,
      label: t("common:menu.billing"),
      url: "/billing",
      isRouteActive: location === "/billing" || location.includes("/billing"),
    });

    return items;
  };

  const sidebarItems = buildSidebarItems();

  return (
    <>
      <Header />

      <div className="layout-container d-flex min-vh-100">
        {/******** Sidebar **********/}
        <aside className="sidebar-wrapper">
          <Sidebar items={sidebarItems} />
        </aside>

        {/********Content Area**********/}
        <main className="content-wrapper d-flex flex-column flex-grow-1">
          {/********topnav**********/}
          <TopNav showMobmenu={() => showMobilemenu()} />

          {/********Middle Content**********/}
          <div
            className="main-content position-relative border-radius-lg flex-grow-1"
            style={{ marginLeft: "10px" }}
          >
            <div className="container-fluid py-4">{children}</div>
          </div>

          {/********Footer**********/}
          <Footer />
        </main>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable={false}
        pauseOnVisibilityChange
        closeOnClick
        pauseOnHover
      />
    </>
  );
};

export default AuthLayout;
